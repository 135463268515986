<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          outlined
          :rules="rules.requiredRule"
          label="First Name"
          v-model="formData.first_name"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          :rules="rules.requiredRule"
          label="Last Name"
          v-model="formData.last_name"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="row-mt-3" cols="12" v-if="!hideEmail">
        <v-text-field
          :rules="rules.emailRule"
          label="Email"
          v-model="formData.email"
          outlined
        ></v-text-field>
      </v-col>
      <v-col class="row-mt-3" cols="8">
        <v-text-field
          :rules="rules.mobileRule"
          v-model="formData.mobile"
          label="Mobile"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="4" class="row-mt-3">
        <v-combobox
          :items="yearList"
          outlined
          label="Birth Year"
          v-model="formData.birthYear"
        ></v-combobox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import rules from '@/mixins/rules'
export default {
  mixins: [rules],
  props: {
    value: {
      type: Object
    },
    hideEmail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: {}
    }
  },
  methods: {
    setData() {
      this.formData = this.value
    },
    emitValue() {
      this.$emit('input', this.formData)
    }
  },
  watch: {
    value: {
      handler: 'setData',
      immediate: true
    },
    formData: {
      handler: 'emitValue'
    }
  }
}
</script>

<style></style>
