<template>
  <div class="ma-2">
    <v-card-title
      >Update Profile
      <v-spacer></v-spacer>
      <a
        target="_blank"
        class="decoration-none"
        :href="`mailto:${formData.email}`"
        >{{ formData.email ? formData.email : 'Loading...' }}</a
      >
    </v-card-title>
    <div>
      <div v-if="user">
        <v-form
          ref="form"
          v-model="forms.profile"
          @submit.prevent="updateProfile()"
        >
          <v-alert v-if="success.profile" type="success"
            >Profile updated</v-alert
          >
          <v-alert v-if="error.profile" type="error">{{
            error.profile
          }}</v-alert>
          <user-profile-form v-model="formData" :hideEmail="true">
          </user-profile-form>
          <div>
            <v-btn
              :loading="loading"
              @click="updateProfile()"
              color="primary"
              large
              class="text-capitalize"
              >Update <v-icon>mdi-content-save</v-icon></v-btn
            >
          </div>
          <input type="submit" hidden />
        </v-form>

        <v-divider class="mt-2 mb-2"></v-divider>
        <v-form
          @submit.prevent="changePassword"
          ref="changePasswordForm"
          class="mt-3"
          v-model="forms.changePassword"
        >
          <v-alert v-if="success.changePassword" type="success">{{
            success.changePassword
          }}</v-alert>
          <v-alert
            v-if="error.changePassword"
            class="text-center"
            type="error"
            >{{ error.changePassword }}</v-alert
          >
          <v-row>
            <v-col>
              <v-text-field
                outlined
                name="new_password"
                label="New Password"
                type="password"
                v-model="formData.password"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                name="confirm_password"
                label="Confirm Password"
                type="password"
                v-model="formData.confirm_password"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
            :loading="loadingPassword"
            @click="changePassword()"
            color="success"
            large
            >Change Password <v-icon>mdi-key</v-icon></v-btn
          >
          <input type="submit" hidden />
        </v-form>
      </div>
      <div v-else class="text-center">
        <v-progress-circular
          indeterminate
          size="80"
          width="7"
          color="primary"
          class="mt-5"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import user from '@/mixins/user'
import rules from '@/mixins/rules'
import 'firebase/auth'
import firebase from 'firebase/app'
import audio from '@/mixins/audio'
import UserProfileForm from '../UserProfileForm.vue'
export default {
  mixins: [user, rules, audio],
  components: {
    UserProfileForm
  },
  data: () => ({
    loading: false,
    formData: { password: '', confirm_password: '' },
    loadingPassword: false,
    success: {
      profile: null,
      changePassword: null
    },
    error: {
      profile: null,
      changePassword: null
    },
    forms: {
      profile: true,
      changePassword: true
    }
  }),
  computed: {
    user() {
      return this.$store.getters.user
    }
  },
  mounted() {
    if (this.user) {
      this.formData = {
        email: this.user.email,
        ...this.user.profile
      }
    }
  },
  methods: {
    async updateProfile() {
      this.$refs.form.validate()
      if (this.forms.profile) {
        this.loading = true
        var user = await this.currentUser()

        delete this.formData.password
        delete this.formData.confirm_password

        this.changeProfileData(
          {
            ...this.formData
          },
          user
        )

        this.success.profile = true
        this.loading = false
        this.updateUser(user)
        this.playFrog()
        setTimeout(() => {
          this.success.profile = false
        }, 3000)
      }
    },
    async changePassword() {
      this.error.changePassword = null
      if (!this.formData.password) {
        this.forms.changePassword = false
        this.error.changePassword = 'Password is required'
        return
      }
      if (this.formData.password != this.formData.confirm_password) {
        this.forms.changePassword = false
        this.error.changePassword = 'Password and Confirm Password must be same'
      } else {
        this.forms.changePassword = true
      }
      if (this.forms.changePassword) {
        this.loadingPassword = true
        await firebase
          .auth()
          .currentUser.updatePassword(this.formData.password)
          .then(() => {
            this.success.changePassword = 'Password changed'
            setTimeout(() => {
              this.success.changePassword = null
            }, 3000)
          })
          .catch(err => {
            if (err.message) {
              this.error.changePassword = err.message
            }
          })
        this.loadingPassword = false
        this.playFrog()
        this.formData.password = ''
        this.formData.confirm_password = ''
      }
    }
  },
  watch: {
    user() {
      this.formData = {
        email: this.user.email,
        ...this.user.profile
      }
    }
  }
}
</script>

<style></style>
